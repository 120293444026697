import 'bootstrap';
import { dom } from '@fortawesome/fontawesome-svg-core';


//import "bootstrap_override.scss";
//import "bootstrap/scss/bootstrap.scss";
import "./default.scss";

dom.watch();

$(function () {
    $('[data-toggle="popover"]').popover()
});
//$('p').html('<i class="fas fa-star"></i>');
